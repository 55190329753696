<template>
  <section />
</template>

<script>
// eslint-disable-next-line import/no-cycle
import AuthAPI from '../api/modules/Auth';

export default {
  name: 'DataSourceCallback',
  created() {
    this.setAppLoadingState(true);
    const oAuthOptions = {};
    if (typeof this.$route.query.error !== 'undefined') {
      oAuthOptions.error = this.$route.query.error;
    }
    if (typeof this.$route.query.code !== 'undefined') {
      oAuthOptions.code = this.$route.query.code;
    }
    if (typeof this.$route.query.state !== 'undefined') {
      oAuthOptions.state = this.$route.query.state;
    }
    if (typeof this.$route.query.oauth_token !== 'undefined') {
      oAuthOptions.oauth_token = this.$route.query.oauth_token;
    }
    if (typeof this.$route.query.oauth_verifier !== 'undefined') {
      oAuthOptions.oauth_verifier = this.$route.query.oauth_verifier;
    }
    if (typeof this.$route.query.shop !== 'undefined') {
      oAuthOptions.shop = this.$route.query.shop;
    }

    AuthAPI.callback(oAuthOptions).then((res) => {
      if(res.data.success) {
        this.$buefy.toast.open({
          message: 'Data source added',
          type: 'is-success',
        });
      } else {
        this.$buefy.toast.open({
          message: res.data.message || 'Authorization Failed',
          type: res.data.isWarning ? 'is-warning' : 'is-danger',
          duration: 3000
        });
      }

      this.setAppLoadingState(false);

      if (typeof res.data.scriptUrl !== 'undefined') {
        window.location = res.data.scriptUrl;
      } else {
        this.$router.push({name: 'DataSources'});
      }
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.log(error);

      this.$buefy.toast.open({
        message: error?.response?.data?.error || 'Request Failed',
        type: 'is-danger',
        duration: 3000
      });

      this.setAppLoadingState(false);
      this.$router.push({name: 'DataSources'});
    });
  },
};
</script>
